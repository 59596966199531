/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';


import francelogo from '../../images/drapeaux/francedrapeau.png'
import USddrapeau from '../../images/drapeaux/USddrapeau.png'
import RoyaumeUnidrapeau from '../../images/drapeaux/RoyaumeUnidrapeau.png'
import Drawer from "../../components/Drawer";
import { Link } from "react-router-dom";
import Navbarfooter from "../../components/Navbarfooter";
import StackedBarChart from "../../components/chartgraphique/BarChart";
import { getMarches, getDataForCurrentMinutes, postAchat, getImagesByMarketType, getMarchesByType, getAvailableCountriesByType, getValidMarketsByUserLeague } from '../../Api/authApi.js';
import { useParams } from 'react-router-dom';

const PorteFeuilleBitcoin = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedButton, setSelectedButton] = useState('France');
    const [affichetitle, setaffichetitle] = useState('');
    // const [countries, setCountries] = useState([]);
    const [markets, setMarkets] = useState({});
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [marketData, setMarketData] = useState([]);
    const [ValidMarkets, setValidMarkets] = useState([]);
    const { type } = useParams(); // Récupérer le paramètre "type" depuis l'URL
    const countries = ['FRA', 'USA', 'GB'];
    const [loading, setLoading] = useState(false); // Indicateur de chargement

    const [errorMessage, setErrorMessage] = useState(''); // État pour stocker le message d'erreur
   
    const fetchMarketData = async (country) => {
        setValidMarkets([]); // Vider les données actuelles
        setErrorMessage(''); // Réinitialiser l'erreur
        setLoading(true); // Définir l'état de chargement

        try {
            const data = await getValidMarketsByUserLeague(type, country); // Récupérer les données

            if (data.valid_marches.length > 0) {
                setValidMarkets(data.valid_marches); // Mettre à jour les marchés si des données sont trouvées
                setErrorMessage(''); // Réinitialiser l'erreur si des données sont disponibles
            } else {
                setValidMarkets([]); // Vider les marchés s'il n'y a pas de données
                setErrorMessage(`Aucun marché disponible pour ${country}.`); // Message si pas de données
            }
        } catch (error) {
            // console.error('Erreur lors de la récupération des marchés :', error.message);
            setErrorMessage(`Aucun marché disponible pour ${country}.`); // Message si pas de données
        } finally {
            setLoading(false); // Fin du chargement
        }
    };

    const handleCountryClick = (country) => {
        setSelectedCountry(country); // Mettre à jour le pays sélectionné
        fetchMarketData(country); // Appeler la fonction pour récupérer les données
    };

    // useEffect pour charger par défaut les données du premier pays à l'initialisation
    useEffect(() => {
        if (countries.length > 0) {
            setSelectedCountry(countries[0]); // Sélectionner le premier pays
            fetchMarketData(countries[0]); // Charger les données du premier pays par défaut
        }
    }, [type]); // Se déclenche uniquement au montage ou si "type" change

    // useEffect pour rafraîchir les données toutes les minutes pour le pays sélectionné
    useEffect(() => {
        if (selectedCountry && type) {
            const interval = setInterval(() => {
                fetchMarketData(selectedCountry); // Rafraîchir les données toutes les minutes
            }, 60000); // 60 000 ms = 1 minute

            // Nettoyage de l'intervalle quand le composant est démonté ou si selectedCountry/type change
            return () => clearInterval(interval);
        }
    }, [selectedCountry, type]);

    //     try {
    //         const countryMarkets = markets[country] || [];

    //         // console.log(`Marchés pour ${country} :`, countryMarkets);

    //         // Effacer les données de marché actuelles
    //         setMarketData([]);

    //         // Récupérer les données pour chaque marché dans ce pays
    //         const marketDataPromises = countryMarkets.map(async (market) => {
    //             const data = await getDataForCurrentMinutes(market.id);
    //             return { market, data };
    //         });

    //         // Attendre que toutes les données de marché soient récupérées
    //         const allMarketData = await Promise.all(marketDataPromises);

    //         if (allMarketData.length > 0) {
    //             // Définir les données du premier marché comme valeur par défaut
    //             setMarketData([allMarketData[0]]);
    //             setaffichetitle(allMarketData[0].market.titre); // Afficher le titre du marché

    //         }

    //         // console.log(`Data pour les marchés de ${country} :`, allMarketData);
    //     } catch (error) {
    //         console.error("Erreur lors de la récupération des données de marché :", error.message);
    //     }
    // };
    const formatDifference = (difference) => {
        if (difference > 0) {
            return `+${difference.toFixed(2)}`; // Ajouter le signe + pour les valeurs positives
        } else if (difference < 0) {
            return `${difference.toFixed(2)}`; // Le signe - est déjà inclus pour les valeurs négatives
        } else {
            return difference.toFixed(2); // Pour les valeurs nulles
        }
    };

    const getChangeColor = (difference) => {
        if (difference > 0) {
            return 'text-green-500'; // Couleur pour les valeurs positives
        } else if (difference < 0) {
            return 'text-red-500'; // Couleur pour les valeurs négatives
        } else {
            return 'text-gray-500'; // Couleur pour les valeurs nulles ou égales à zéro
        }
    };

    return (
        <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] lg:pb-auto pb-12">

            <div className="grid  lg:grid-cols-1  md:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 grid-cols-1 sm:grid-cols-1 flex-col items-center justify-center  gap-7 sm:px-10 md:px-16">


                <div className="lg:w-[98%] w-[96%] lg:p-4 p-2 lg:border md:border  sm:border rounded-lg shadow bg-balck text-white flex    mx-auto flex-col ">

                    <div className="flex flex-row">
                        {countries.map(country => (
                            <button
                                key={country}
                                onClick={() => handleCountryClick(country)}

                                className={`border rounded-xs w-1/3 justify-center items-center cursor-pointer ${selectedCountry === country ? 'bg-white text-black' : 'bg-[#252f3d] text-gray-300'} font-normal lg:text-[15px] lg:font-medium text-[13px] text-center px-4 py-1.5 2xl:p-2 xl:p-2 lg:p-2 md:p-2`}
                            >
                                {country}
                            </button>
                        ))}
                    </div>
                    <div className="flex  justify-between mx-3 py-3">
                        <p className='p-2'>{ }</p>
                        <button className={` hover:bg-white hover:text-black  border rounded-xs  text-gray-300 w-auto px-4   justify-center items-center   bg-[#252f3d] font-normal text-[13px] text-center flex  gap-4  `}>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel" viewBox="0 0 16 16">
                                    <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                                </svg>
                            </span>
                            <span>Trier par
                            </span>
                        </button>
                    </div>
                    {ValidMarkets.length > 0 && !loading && (
                        <div>
                            <h3>{ValidMarkets[0].pays}</h3> {/* Afficher le pays */}
                            {ValidMarkets.map((market) => (
                                <div key={market.marche_id} className="flex justify-between px-3 py-3 border-b border-gray-500">
                                    <div className="flex gap-2 items-center">
                                        <div className="relative">
                                            {/* <img src={`https://tradingseries.fr/admin/public${market.icon}`} className="w-14 h-14 rounded-full" alt={market.titre} /> */}
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="text-[14px] lg:text-md font-semibold">{market.titre}</p>
                                            <p className="text-[12px] lg:text-md font-semibold flex gap-2 items-center ">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="red" className="bi bi-clock-fill" viewBox="0 0 16 16">
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                                    </svg>
                                                </span>{market.heure_complete}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center flex-col font-medium">
                                        <p className="text-[12px] lg:text-md ">
                                            {parseFloat(market.current_value).toFixed(3)}
                                        </p>
                                        <p className={`text-[11px] lg:text-md ${getChangeColor(market.difference)}`}>
                                            {formatDifference(market.difference)}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {!loading && ValidMarkets.length === 0 && errorMessage && (
                        <div className="text-center py-5">
                            <p>{errorMessage}</p>
                        </div>
                    )}
                </div>

            </div>
        </div>

    )
};
export default PorteFeuilleBitcoin;
