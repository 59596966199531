/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import ReactFlagsSelect from "react-flags-select";
import * as d3 from 'd3';  // Utiliser d3 pour charger et parser les données CSV
import bitcoin from '../../images/bitcoin.png'
import totalenergielogo from '../../images/imgprof/totalenergielogo.png'
import francelogo from '../../images/drapeaux/francedrapeau.png'
import USD from '../../images/USD.png'
import Ethereum from '../../images/Ethereumimg.png';
import StockChart from "../../components/chartgraphique/StockChart";
import { useParams } from 'react-router-dom';
import {
    getMarches, getDataForCurrentMinutes, postAchat, getImagesByMarketType, getMarchesByType, getDatachartjb1m,
    getDataForCurrentAndLast10Minutes,

    getDataForCurrentAndLast30Minutes,
    getDataForCurrentAndLastHour,
    getDataForCurrentAndLastDay,
} from '../../Api/authApi.js';
import axios from 'axios'; // Import Axios
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';


const PorteFeuilleDetail = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const navigate = useNavigate(); // Ajouter cette ligne
    const [selectedTime, setSelectedTime] = useState('10m');
    const userId = useSelector((state) => state.auth.user.id);



   

    const [marches, setMarches] = useState([]);
    const [marchesid, setmarchesid] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedMarche, setSelectedMarche] = useState(null);
    const [marketData, setMarketData] = useState({}); // Ajouter l'état pour les données du marché
    const [marketImages, setMarketImages] = useState({});
    const { type } = useParams(); // Récupérer le paramètre "type" depuis l'URL
    const [countries, setCountries] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [data, setData] = useState(null); // État pour stocker les données récupérées
    const [leagueid, setleagueid] = useState(0);


    const handleButtonClick = async (timeOption) => {

        setLoading(true);
        setError(null);
        setData(null); // Réinitialise les données pendant le chargement

        try {
            const result = await timeOption.function(marchesid); // Appelle la fonction associée
            setData(result); // Met à jour les données
            console.log('result', result)
            setSelectedPeriod(timeOption.key); // Met à jour la période sélectionnée
        } catch (error) {
            setError(error.message); // Capture et stocke l'erreur
        } finally {
            setLoading(false); // Arrête l'état de chargement
        }
    };

    const handleClick = (marche) => {
        setSelectedMarche(marche);
        setmarchesid(marche.marche_id
        );
        setleagueid(marche.league_id
        );
        // console.log('marchesid','league_id',marchesid,leagueid)
      };
      useEffect(() => {
        const fetchMarches = async () => {
          try {
            setLoading(true); // Assurez-vous que le chargement commence
            const data = await getMarches(userId); // Appel à l'API avec le user_id
            // console.log('marchés',data)
    
            setMarches(data);
            // console.log('marchés',data)
            if (data.length > 0) {
              setSelectedMarche(data[0]); // Sélectionnez le premier marché
              setmarchesid(data[0].marche_id
              ); // Initialisez l'ID du premier marché
              setleagueid(data[0].league_id
              ); // Initialisez l'ID du premier marché
              // setmarchesid(data[0].id); // Initialisez l'ID du premier marché
            }
          } catch (err) {
            setError(err.message);
          } finally {
            setLoading(false);
          }
        };
    
        if (userId) {
          fetchMarches();
        }
      }, [userId]);
    // useEffect(() => {
    //     const fetchMarketData = async () => {
    //         if (marches.length > 0) {
    //             try {
    //                 const dataPromises = marches.map(marche =>
    //                     getDataForCurrentMinutes(marche.id).then(data => ({
    //                         id: marche.id,
    //                         date: data.date,
    //                         day: data.day,
    //                         hour: data.hour,
    //                         minute: data.minute,
    //                         last_value: data.last_value,
    //                         difference: data.difference,
    //                         value: parseFloat(data.value).toFixed(2) // Arrondir la valeur ici
    //                     }))
    //                 );
    //                 const results = await Promise.all(dataPromises);
    //                 const marketDataMap = results.reduce((acc, { id, date, day, hour, minute, value, difference }) => {
    //                     acc[id] = {
    //                         date, day, hour, minute, value, difference
    //                     };
    //                     return acc;
    //                 }, {});
    //                 setMarketData(marketDataMap);
    //                 // console.log('marketDataMap', marketDataMap);
    //             } catch (err) {
    //                 setError(err.message);
    //             } finally {
    //                 setLoading(false);
    //             }
    //         }
    //     };

    //     fetchMarketData();
    //     const intervalId = setInterval(fetchMarketData, 60000); // 60000ms = 1 minute

    //     return () => clearInterval(intervalId); // Nettoyage de l'intervalle
    // }, [marches]);
    useEffect(() => {
        const fetchMarketData = async () => {
          if (marches.length > 0) {
            try {
              // Créer des promesses pour chaque marché avec gestion individuelle des erreurs
              const dataPromises = marches.map(marche =>
                getDataForCurrentMinutes(marche.marche_id, marche.league_id)
                  .then(data => ({
                    id: marche.id,
                    marche_id: marche.marche_id,
                    league_id: marche.league_id,
                    date: data.date,
                    day: data.day,
                    hour: data.hour,
                    minute: data.minute,
                    last_value: data.last_value,
                    difference: data.difference,
                    type: data.type,
                    value: parseFloat(data.value).toFixed(2) // Arrondir la valeur ici
                  }))
                  .catch(err => {
                    console.error(`Erreur pour le marché ${marche.id}: ${err.message}`);
                    return null; // Continuer même si un marché échoue
                  })
              );
      
              // Attendre que toutes les promesses soient résolues, même celles qui ont échoué
              const results = await Promise.all(dataPromises);
      
              // Filtrer les résultats pour ignorer ceux qui ont échoué
              const validResults = results.filter(result => result !== null);
      
              // Réduire les résultats en un objet { marcheId: { date, day, hour, minute, value, difference } }
              const marketDataMap = validResults.reduce((acc, { id, marche_id,date, day, hour, minute, value, difference,type ,league_id}) => {
                acc[id] = { date,marche_id, day, hour, minute, value, difference ,league_id,type};
                return acc;
              }, {});
      
              // Mettre à jour les données de marché
              setMarketData(marketDataMap);
              // console.log('marketDataMap', marketDataMap);
            } catch (err) {
              setError(err.message);
            } finally {
              setLoading(false);
            }
          }
        };
      
        fetchMarketData();
        const intervalId = setInterval(fetchMarketData, 60000); // 60000ms = 1 minute
      
        return () => clearInterval(intervalId); // Nettoyage de l'intervalle
      }, [marches, userId]);
      
    useEffect(() => {
        const fetchMarketType = async () => {
            try {
                const response = await getImagesByMarketType();
                setMarketImages(response);
                // console.log(response)
            } catch (err) {
                setError(err.message);
            }
        };

        fetchMarketType();
    }, []);

    useEffect(() => {
        const fetchMarkets = async () => {
          try {
            const markets = await getMarchesByType('Actions');
            // console.log(markets);
          } catch (error) {
            console.error(error.message);
          }
        };
    
        fetchMarkets();
      }, []);
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`https://tradingseries.fr/admin/public/api/marches/countries`, {
                    params: { type }
                });
                setCountries(response.data);
                console.log(response.data);
            } catch (error) {
                setError(error.message);
                console.error(error.message);
            }
        };

        fetchCountries();
    }, [type]);

    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const [currentIndex, setCurrentIndex] = useState(0); // Suivre l'index actuel
    const isSmallScreen = window.innerWidth < 768; // sm: 768px et moins

    const cardsPerPagee = isSmallScreen ? 2 : 4; // Nombre de cartes par page
    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => Math.max(prevIndex - cardsPerPagee, 0));
    };

    // Gérer le clic pour afficher les cartes suivantes
    const handleNext = () => {
        setCurrentIndex((prevIndex) => Math.min(prevIndex + cardsPerPagee, marches.length - cardsPerPagee));
    };
    const visibleMarches = marches.slice(currentIndex, currentIndex + cardsPerPagee);


    const handleAchat = async () => {
   

        const { id,marche_id, titre, icon,type,league_id } = selectedMarche;
        // console.log('selectedMarche',selectedMarche)
        let { value, date, hour, difference} = marketData[id] || {};
        const userId = user.id;
        // console.log('marketData[id]', marketData)
    
      
        value = parseFloat(value).toFixed(2);
    
      
        const dateObj = new Date(date);
    
        if (isNaN(dateObj.getTime())) {
     
          return;
        }
    
       
        try {
          const response = await postAchat(userId, marche_id, value,type,league_id);
          // console.log('Achat effectué avec succès:', response);
          navigate('/portefeuille_achat', { state: { marche_id, titre, icon, value, hour, difference,type,league_id } });
        } catch (error) {
          console.error('Erreur lors de l\'achat:', error);
          alert('Une erreur est survenue lors de l\'achat. Veuillez réessayer.');
        }
      };
    
      const handleVente = async () => {
    
        const { id,marche_id, titre, icon,type,league_id } = selectedMarche;
        let { value, date, hour, difference} = marketData[id] || {};
        const userId = user.id;
    
        // Vérifier que l'heure est correctement formatée
        // if (!hour || typeof hour !== 'string' || hour.split(':').length !== 2) {
        //   console.error('Heure invalide:', hour);
        //   // alert('Erreur: L\'heure est invalide.');
        //   return;
        // }
    
        // Extraire les heures et minutes depuis la chaîne de caractères hour
        // const [hourPart, minutePart] = hour.split(':').map(Number);
    
        // if (isNaN(hourPart) || isNaN(minutePart)) {
        //   console.error('Heure ou minute invalide:', { hourPart, minutePart });
        //   // alert('Erreur: L\'heure ou la minute est invalide.');
        //   return;
        // }
    
        // Arrondir la valeur à deux décimales
        value = parseFloat(value).toFixed(2);
    
        // const formattedTime = `${String(hourPart).padStart(2, '0')}:${String(minutePart).padStart(2, '0')}`;
    
        // Construire l'objet Date à partir des informations de date, heure et minute
        const dateObj = new Date(date);
    
        // if (isNaN(dateObj.getTime())) {
        //   // console.error('Date invalide:', date);
        //   // alert('Erreur: La date est invalide.');
        //   return;
        // }
    
        // dateObj.setHours(hourPart + 1); // Ajuster l'heure pour le fuseau horaire GMT+1
        // dateObj.setMinutes(minutePart);
        // dateObj.setSeconds(0);
        // console.log('dateObj', dateObj);
    
        // Formater la date dans le format 'YYYY-MM-DD HH:MM:SS'
        const formattedDate = dateObj.toISOString().slice(0, 19).replace('T', ' ');
        // console.log('Date formattée:', formattedDate);
        try {
          const response = await postAchat(userId, marche_id, value,type,league_id);
          // console.log('Achat effectué avec succès:', response);
          navigate('/portefeuille_vente', { state: { marche_id, titre, icon, value, hour, difference,type,league_id} });
        } catch (error) {
          // console.error('Erreur lors de l\'achat:', error);
          alert('Une erreur est survenue lors de l\'achat. Veuillez réessayer.');
        }
      
      };
    

    if (!selectedMarche) return null;






    return (
        <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] py-7 pb-12">
            <div className="grid  lg:grid-cols-1   md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 grid-cols-1 sm:grid-cols-1 flex-col  gap-4 sm:px-10 md:px-16">
                <div className="lg:w-[98%] w-[98%] p-2 lg:border border-gray-400 lg:rounded-lg  sm:border md:border  xl:border 2xl:border shadow bg-balck text-white flex justify-center mx-auto  py-1 lg:py-4  flex-col border-b ">


                    <div className="relative  p-2">
                        {/* Bouton gauche */}
                        <div className="absolute inset-y-1/3 -left-2 flex items-center justify-center transform -translate-y-1/2">
                            <button
                                onClick={handlePrevious}
                                className="bg-gray-800 text-white p-1.5 rounded-full shadow-md hover:bg-gray-700 transition duration-300"
                                disabled={currentIndex === 0} // Désactiver si au début
                            >
                                <svg className="w-3 h-3 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 1 1.3 6.326a.91.91 0 0 0 0 1.348L7 13" />
                                </svg>
                            </button>
                        </div>

                        {/* Bouton droit */}
                        <div className="absolute inset-y-1/3 -right-2 flex items-center justify-center transform -translate-y-1/2">
                            <button
                                onClick={handleNext}
                                className="bg-gray-800 text-white p-1.5 rounded-full shadow-md hover:bg-gray-700 transition duration-300"
                                disabled={currentIndex + cardsPerPagee >= marches.length} // Désactiver si à la fin
                            >
                                <svg className="w-3 h-3 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1" />
                                </svg>
                            </button>
                        </div>

                        {/* Affichage des cartes */}
                        <div className="flex gap-4 justify-center items-center">
                            {visibleMarches.map((marche) => (
                                <div key={marche.id} onClick={() => handleClick(marche)} className="cursor-pointer bg-slate-900 rounded-2xl p-2 flex gap-2 justify-center items-center shadow-md w-[100%] flex-row ">
                                    <img
                                        // src={`http://127.0.0.1:8000/storage/app/public/${marche.icon}`}
                                        src={`https://tradingseries.fr/admin/storage/app/public/${marche.icon}`}
                                        alt={marche.titre}
                                        className="w-12 h-12 rounded-full mb-2"
                                    />
                                    <div className="flex flex-col ">
                                        <p className="text-[7px] font-bold text-white">{marche.type}</p>
                                        <p className="text-[8px] font-semibold">{marche.titre}</p>
                                        <p className="text-[9px] font-semibold">
                                            {marketData[marche.id] ? `${marketData[marche.id].value}€` : ''}
                                        </p>
                                        <p className="text-[8px] font-semibold">
                                            {marketData[marche.id] ? `${marketData[marche.id].date}` : ''}
                                        </p>
                                        <p className="text-[8px] font-semibold">
                                            {marketData[marche.id] ? `${marketData[marche.id].hour}` : ''}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {selectedMarche && (

                            <div className=" flex  justify-between gap-1 items-center py-4">
                                <div className='flex gap-2 flex-row justify-center text-center items-center'>
                                    <img src={`https://tradingseries.fr/admin/storage/app/public/${selectedMarche.icon}`} className="w-9 bg-transparent rounded-full"></img>
                                    <p className="text-[12px] font-semibold">{selectedMarche.titre}</p>
                                </div>
                            </div>
                        )}
                    </div>

                    <StockChart marcheId={marchesid}  leagueid={leagueid} />

                    <div className='flex justify-center items-center'>
                        {selectedMarche && (

                            <div className=" flex  items-center  justify-between gap-1  py-4">

                                <div className="  flex  justify-center  items-center gap-4" >

                                    <button className="py-2.5 px-8 text-[14px] bg-[#82bf3e] rounded-sm text-white font-semibold " onClick={handleAchat}
                                    >
                                        ACHAT
                                    </button>
                                    <button className="py-2.5 px-8 text-[14px] bg-[#97271d] rounded-sm text-white font-semibold" onClick={handleVente}
                                    >
                                        VENTE
                                    </button>
                                </div>
                            </div>
                        )}

                    </div>
                    {/* <div className="flex flex-col gap-3 py-2">
                        <div className=" flex justify-between mx-4 text-sm">
                            <p>Volume (3M) :</p>
                            <p>3,98M</p>
                        </div>
                        <div className=" flex justify-between mx-4 text-sm">
                            <p>Capitalisation boursière :</p>
                            <p>  143,99B</p>
                        </div>
                    </div> */}


                </div>
                <div className="lg:w-[98%] w-[98%] p-2  sm:border md:border lg:border xl:border 2xl:border border-gray-200 lg:rounded-lg shadow bg-balck text-white flex py-2 lg:pt-20 mx-auto   flex-col  ">
                    <div className="flex  flex-col gap-3 mx-3">
                        {selectedMarche && (

                            <>
                                <p className="text-lg font-medium  text-justify">Profil</p>

                                <p className="text-[13px] lg:text-lg font-medium  text-justify">{selectedMarche.description}</p></>

                        )}
                    </div>
                </div>


            </div>
        </div>

    )
};
export default PorteFeuilleDetail;
