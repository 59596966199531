// // src/BarChart.js
// import React from 'react';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const BarChart = () => {
//     const labels = ['Semaine 1', 'Semaine 2', 'Semaine 3', 'Semaine 4'];
//     const dataValues = [10000, 9000, 12000, 8000,]; // Valeurs augmentant de 2000 à 12000

//     const data = {
//         labels: labels,
//         datasets: [
//             {
//                 label: 'Performance',
//                 backgroundColor: [
//                     '#7ed957',  // Couleur pour Semaine 1
//                     '#ff3131', // Couleur pour Semaine 2
//                     '#7ed957',  // Couleur pour Semaine 1
//                     '#ff3131', //
//                 ],

//                 borderWidth: 1,
//                 data: dataValues,
//             },
//         ],
//     };

//     const options = {
//         plugins: {
//             title: {
//                 display: true,
//             },
//             legend: {
//                 display: false, 
//             },
//             roundedBars: true, // Activer le plugin des barres arrondies

//         },
//         responsive: true,
//         maintainAspectRatio: false,
//         scales: {
//             x: {
//                 stacked: false,
//             },
//             y: {
//                 stacked: false,
//                 suggestedMax: 12000,
//                 // title: {
//                 //     display: true,
//                 // },
//             },
//         },
//     };

//     return (
//         <div style={{ width: '100%', height: '60vh' }}>
//             <Bar data={data} options={options} />
//         </div>
//     );
// };

// export default BarChart;
// import React, { useEffect, useState } from 'react';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// import { getUserWithTeam,getclassementbyuser } from '../../../Api/authApi.js';
// import { useDispatch, useSelector } from 'react-redux';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const BarChart = ({ userId }) => {
//     const [labels, setLabels] = useState([]);
//     const [dataValues, setDataValues] = useState([]);
//     const user = useSelector((state) => state.auth.user);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response =  await getclassementbyuser(user.id);
//                 const weeklyTotals = response.weekly_totals;
//                 const labels = Object.keys(weeklyTotals);
//                 const dataValues = labels.map(week => parseFloat(weeklyTotals[week].total) || 0);

//                 setLabels(labels);
//                 // setDataValues(dataValues);
//             } catch (error) {
//                 console.error('Erreur lors de la récupération des données :', error.message);
//             }
//         };

//         fetchData();
//     }, [userId]);

//     const data = {
//         labels: labels,
//         datasets: [
//             {
//                 label: 'Performance',
//                 backgroundColor: [
//                     '#7ed957',  // Couleur pour Semaine 1
//                     '#ff3131',  // Couleur pour Semaine 2
//                     '#7ed957',  // Couleur pour Semaine 3
//                     '#ff3131',  // Couleur pour Semaine 4
//                 ],
//                 borderWidth: 1,
//                 data: dataValues,
//             },
//         ],
//     };

//     const options = {
//         plugins: {
//             title: {
//                 display: true,
//             },
//             legend: {
//                 display: false, 
//             },
//             roundedBars: true,
//         },
//         responsive: true,
//         maintainAspectRatio: false,
//         scales: {
//             x: {
//                 stacked: false,
//             },
//             y: {
//                 stacked: false,
//                 suggestedMax: 1200,
//             },
//         },
//     };

//     return (
//         <div style={{ width: '100%', height: '60vh' }}>
//             <Bar data={data} options={options} />
//         </div>
//     );
// };

// export default BarChart;
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import {  checktransactionsPourDefiActif } from '../../../Api/authApi.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({userId}) => {
    const [dataValues, setDataValues] = useState([]); // Pour stocker les montants
    const [labels, setLabels] = useState([]); // Pour stocker les périodes de défi

    useEffect(() => {
     
        const fetchDefiData = async () => {
            try {
                const response = await checktransactionsPourDefiActif(userId);
                const transactionsDefis = response.transactions_defis;
                console.log('transactionsDefis', response)
                // Variables temporaires pour stocker les labels et les montants par période
                const montantParPeriode = {};

                transactionsDefis.forEach(transaction => {
                    const periode = `${transaction.periode.date_debut} - ${transaction.periode.date_fin}`;

                    // Si la période n'existe pas encore dans l'objet, l'initialiser à 0
                    if (!montantParPeriode[periode]) {
                        montantParPeriode[periode] = 0;
                    }

                    // Parcourir les défis de la période et additionner les montants si le défi est atteint
                    transaction.defis.forEach(defi => {
                        if (defi.defi_atteint) {
                            montantParPeriode[periode] += parseFloat(defi.montant_gagner); // Ajouter le montant du défi atteint
                        }
                    });
                });

                // Extraire les labels (périodes) et les montants totaux correspondants
                const tempLabels = Object.keys(montantParPeriode);
                const tempDataValues = Object.values(montantParPeriode);

                // Mettre à jour les labels et les montants du graphique
                setLabels(tempLabels);
                setDataValues(tempDataValues);
            } catch (error) {
                console.error('Erreur lors de la récupération des données des défis:', error);
            }
        };
        fetchDefiData();

    }, [userId]); // useEffect appelé à chaque fois que l'utilisateur est mis à jour

    const data = {
        labels: labels, // Périodes des défis (plages)
        datasets: [
            {
                label: '',
                backgroundColor: '#7ed957',  // Couleur des barres
                borderWidth: 1,
                data: dataValues, // Montants gagnés par période
                barPercentage: 0.3, // Réduire la taille des barres
                categoryPercentage: 0.5, // Réduire l'espacement entre les barres
            },
        ],
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: '',
            },
            legend: {
                display: false,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: false,
                grid: {
                    display: false
                  },
            },
            y: {
                stacked: false,
                grid: {
                    display: false
                  },
                suggestedMax: Math.max(...dataValues) + 500, // Ajuster l'axe Y en fonction des montants
            },
        },
    };

    return (
        <div style={{ width: '100%', height: '60vh' }}>
            <Bar data={data} options={options} />
        </div>
    );
};

export default BarChart;
