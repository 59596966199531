// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import axios from 'axios';
// import { BrowserRouter as Router } from 'react-router-dom';
// import MainNavigator from './navigation'; 
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { Provider } from 'react-redux';
// import store from './redux/store';
// import { createRoot } from 'react-dom/client'; // Correct pour React 18+

// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <Router>
//         <MainNavigator />
//         <ToastContainer
//           position="top-right"
//           autoClose={2000}
//           hideProgressBar={false}
//           newestOnTop={false}
//           closeOnClick
//           rtl={false}
//           pauseOnFocusLoss
//           draggable
//           pauseOnHover
//           theme="dark"
//           limit={6}
//         />
//       </Router>
//     </Provider>
//   </React.StrictMode>,
// );
import React from 'react';
import { createRoot } from 'react-dom/client'; // Correct pour React 18+
import './index.css';
import axios from 'axios';
import { BrowserRouter as Router } from 'react-router-dom';
import MainNavigator from './navigation'; 
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import store from './redux/store';
import { VideoNotification } from './pages';

// Trouver l'élément root
const rootElement = document.getElementById('root');

// Utiliser createRoot à partir de react-dom/client
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
      <VideoNotification />
        <MainNavigator />
        <ToastContainer
          position="top-right"
          autoClose={6000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          limit={6}
          
        />
      </Router>
    </Provider>
  </React.StrictMode>
);
