import axios from 'axios';
import { login, logout } from '../redux/authSlice';
// const API_URL = 'https://tradingseries.fr/admin/public/api';
// const API_URLtsb = 'https://tradingseries.fr/admin/public/api';
const API_URL = 'https://tradingseries.fr/admin/public/api';
const API_URLtsb = 'https://tradingseries.fr/admin/public/api';
// const API_URL = 'http://127.0.0.1:8000/api';
// const API_URLtsb = 'http://127.0.0.1:8000/api';

const register = async (userData) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  const response = await axios.post(`${API_URL}/register`, userData, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': csrfToken,
    },
  });

  return response.data;
};


const loginUser = async (LoginData, dispatch) => {
  try {
    const response = await axios.post(`${API_URLtsb}/login`, LoginData);
    const { message, data } = response.data;

    if (response.status === 200 && data) {
      dispatch(login(data)); // Assurez-vous de dispatcher les données utilisateur correctes
      return response.data;
    } else {
      throw new Error(message || 'Réponse de connexion invalide');
    }
  } catch (error) {
    throw error;
  }
};


const forgetPassword = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/password/email`, email);
    if (response && response.data) {
      return response.data;
    } else {
      throw new Error('Réponse vide ou non conforme.');
    }
  } catch (error) {
    if (error.response) {
      // Erreur de réponse du serveur (status code non 2xx)
      throw new Error(`Erreur du serveur : ${error.response.data.message}`);
    } else if (error.request) {
      // Erreur de requête (pas de réponse reçue)
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      // Erreur autre
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};


// const resetPassword = async (resetData) => {
//   try {
//     const response = await axios.post(`${API_URL}/password/reset`, resetData);
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };

const logoutUser = () => async (dispatch) => {
  try {
    await axios.post(`${API_URL}/logout`);
    dispatch(logout());
  } catch (error) {
    throw error;
  }
}
const resetPassword = async (resetData) => {
  try {
    const response = await axios.post(`${API_URL}/password/reset`, resetData);
    return response.data; // Traiter les données de la réponse en cas de succès
  } catch (error) {
    console.error('Erreur lors de la réinitialisation du mot de passe:', error);
    throw error; // Gérer l'erreur pour l'afficher ou faire une autre action
  }
};

export const updateUsername = async (id, username) => {
  try {
    const response = await axios.put(`${API_URL}/user/username/${id}`, { username });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};




export const updatePassword = async (id, password, passwordConfirmation) => {
  try {
    const response = await axios.put(`${API_URL}/user/password/${id}`, { password, password_confirmation: passwordConfirmation });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
export const getUserWithTeam = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/user/${userId}/team`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`${error.response.data.error}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
export const getclassementbyteam = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/classementbyteam/${userId}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`${error.response.data.error}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
export const getclassementbyuser = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/classementbyuser/${userId}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`${error.response.data.error}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
export const checktransactionsPourDefiActif = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/transactionsPourDefiActif/${userId}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`${error.response.data.error}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};

export const updateUserTeam = async (userId, teamCode) => {
  try {
    const response = await axios.put(`${API_URL}/user/${userId}/teamupdate`, { team_code: teamCode });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(` ${error.response.data.message}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};




// Fonction pour obtenir les données pour la dernière minute
export const getDatachartjb1m = async (marcheId) => {
  try {
    const response = await axios.get(`${API_URL}/getDataForCurrentAndLastMinutejb/${marcheId}`);
    return response.data;
  } catch (error) {
    return handleAxiosError(error);
  }
};

// Fonction pour obtenir les données pour les 10 dernières minutes
export const getDataForCurrentAndLast10Minutes = async (marcheId) => {
  try {
    const response = await axios.get(`${API_URL}/getDataForCurrentAndLast10Minutes/${marcheId}`);
    return response.data;
  } catch (error) {
    return handleAxiosError(error);
  }
};

// Fonction pour obtenir les données pour les 30 dernières minutes
export const getDataForCurrentAndLast30Minutes = async (marcheId) => {
  try {
    const response = await axios.get(`${API_URL}/getDataForCurrentAndLast30Minutes/${marcheId}`);
    return response.data;
  } catch (error) {
    return handleAxiosError(error);
  }
};

// Fonction pour obtenir les données pour le dernier jour
export const getDataForCurrentAndLastDay = async (marcheId) => {
  try {
    const response = await axios.get(`${API_URL}/getDataForCurrentAndLastDay/${marcheId}`);
    return response.data;
  } catch (error) {
    return handleAxiosError(error);
  }
};

// Fonction pour obtenir les données pour la dernière heure
export const getDataForCurrentAndLastHour = async (marcheId) => {
  try {
    const response = await axios.get(`${API_URL}/getDataForCurrentAndLastHour/${marcheId}`);
    return response.data;
  } catch (error) {
    return handleAxiosError(error);
  }
};

// Gestion des erreurs pour toutes les fonctions
const handleAxiosError = (error) => {
  if (error.response) {
    throw new Error(`Erreur du serveur : ${error.response.data.message}`);
  } else if (error.request) {
    throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
  } else {
    throw new Error(`Erreur inattendue : ${error.message}`);
  }
};

const getDataMarchetodya = async (marcheId) => {
  try {
    const response = await axios.get(`${API_URL}/getDataForCurrentDay/${marcheId}`);

    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
// v2
const getMarches = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/getMarchesbyleagueV2/${userId}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Erreur du serveur (code HTTP 4xx ou 5xx)
      throw new Error(`Erreur du serveur : ${error.response.data.message}`);
    } else if (error.request) {
      // Pas de réponse du serveur
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      // Erreur inattendue
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
// const getMarches = async (userId) => {
//   try {
//     const response = await axios.get(`${API_URL}/getMarchesbyleague/${userId}`);
//     return response.data;
//   } catch (error) {
//     if (error.response) {
//       // Erreur du serveur (code HTTP 4xx ou 5xx)
//       throw new Error(`Erreur du serveur : ${error.response.data.message}`);
//     } else if (error.request) {
//       // Pas de réponse du serveur
//       throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
//     } else {
//       // Erreur inattendue
//       throw new Error(`Erreur inattendue : ${error.message}`);
//     }
//   }
// };
const getVideos = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/videos/current/${userId}`);
    return response;
  } catch (error) {
    if (error.response) {
      // Erreur du serveur (code HTTP 4xx ou 5xx)
      throw new Error(`Erreur du serveur : ${error.response.data.message}`);
    } else if (error.request) {
      // Pas de réponse du serveur
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      // Erreur inattendue
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};

export const getCurrentVideo = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/videos/current/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération de la vidéo :", error);
    throw error;
  }
};
const getArticles = async () => {
  try {
    const response = await axios.get(`${API_URL}/getAllArticles`);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Erreur du serveur (code HTTP 4xx ou 5xx)
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      // Pas de réponse du serveur
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      // Erreur inattendue
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
const getImagesByMarketType = async () => {
  try {
    const response = await axios.get(`${API_URL}/market-images`);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Erreur du serveur (code HTTP 4xx ou 5xx)
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      // Pas de réponse du serveur
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      // Erreur inattendue
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
export const getMarchesByTypeAndCountry = async (type, pays) => {
  try {
    const response = await axios.get(`${API_URL}/markettypes`, {
      params: {
        type,
        pays
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};

export const getValidMarketsByUserLeague = async (type, country) => {
  try {
    const response = await axios.get(`${API_URL}/getValidMarketsByUserLeague`, {
      params: { type, pays: country } // Envoi de type et pays comme paramètres
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};


export const getAvailableCountriesByType = async (type) => {
  try {
    const response = await axios.get(`${API_URL}/marketbycountries`, {
      params: { type }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
// const getDefis = async () => {
//   try {
//     const response = await axios.get(`${API_URL}/defis`);
//     return response.data;
//   } catch (error) {
//     if (error.response) {
//       // Erreur du serveur (code HTTP 4xx ou 5xx)
//       throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
//     } else if (error.request) {
//       // Pas de réponse du serveur
//       throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
//     } else {
//       // Erreur inattendue
//       throw new Error(`Erreur inattendue : ${error.message}`);
//     }
//   }
// };
const getDefis = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/defieActuelle/${userId}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Erreur du serveur (code HTTP 4xx ou 5xx)
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      // Pas de réponse du serveur
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      // Erreur inattendue
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
const getMarchesByType = async (type) => {
  try {
    const response = await axios.get(`${API_URL}/marchestype`, {
      params: { type }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
export const getAllMarketTypes = async () => {
  try {
    const response = await axios.get(`${API_URL}/market-types`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};

// Example usage:
// getMarchesByType('Actions')
//   .then(data => console.log(data))
//   .catch(error => console.error(error));

const getArticleById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/getArticleById/${id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
export const getTransactionDetails = async (transactionId) => {
  try {
    const response = await axios.get(`${API_URL}/getTransactionDetails`, {
      params: { transaction_id: transactionId },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
export const closeTransaction = async (uniqueId, currentValue) => {
  try {
    const response = await axios.post(`${API_URL}/closeTransaction`, {
      unique_id: uniqueId,
      current_value: currentValue

    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};





export const getPortefeuilleData = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/getPortefeuilleData/${userId}`);
    return response.data; // Retournez les données de la réponse
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
// export const getAllPendingTransactions = async (userId) => {
//   try {
//     const response = await axios.get(`${API_URL}/getAllPendingTransactions/${userId}`);
//     return response.data; // Retournez les données de la réponse
//   } catch (error) {
//     if (error.response) {
//       throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
//     } else if (error.request) {
//       throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
//     } else {
//       throw new Error(`Erreur inattendue : ${error.message}`);
//     }
//   }
// };
// v2
export const getAllPendingTransactions = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/portefeuille/getTransactionsPendingParMarche/${userId}`);
    return response.data; // Retournez les données de la réponse
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
export const getAllTransactions = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/getAllTransactions/${userId}`);
    return response.data; // Retournez les données de la réponse
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};

// export const closeTransaction = async (transactionId, userId) => {
//   try {
//     const response = await axios.post(`${API_URL}/closeTransaction`, {
//       transaction_id: transactionId,
//       user_id: userId
//     });
//     return response.data; // Retourner directement les données
//   } catch (error) {
//     if (error.response) {
//       throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
//     } else if (error.request) {
//       throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
//     } else {
//       throw new Error(`Erreur inattendue : ${error.message}`);
//     }
//   }
// };



const addLike = async (id, userId) => {
  try {
    const response = await axios.post(`${API_URL}/like/${id}`, {
      user_id: userId
    });
    return response;
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};

const addDislike = async (id, userId) => {
  try {
    const response = await axios.post(`${API_URL}/dislike/${id}`, {
      user_id: userId
    });
    return response;
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};

const addRate = async (id, userId) => {
  try {
    const response = await axios.post(`${API_URL}/rate/${id}`, {
      user_id: userId,
    });
    return response;
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};


const addComment = async (articleId, userId, comment) => {
  try {
    const response = await axios.post(`${API_URL}/articles/${articleId}/comments`, {
      user_id: userId,
      comment: comment,  // Passer le texte du commentaire
    });
    return response;
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};




// const getDataForCurrentMinutes = async (marcheId) => {
//   try {
//     const response = await axios.get(`${API_URL}/getDataForCurrentminuts/${marcheId}`);
//     return response.data;
//   } catch (error) {
//     if (error.response) {
//       throw new Error(`Erreur du serveur : ${error.response.data.message}`);
//     } else if (error.request) {
//       throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
//     } else {
//       throw new Error(`Erreur inattendue : ${error.message}`);
//     }
//   }
// };
// v2
const getDataForCurrentMinutes = async (marcheId, leagueid) => {
  try {
    const response = await axios.get(`${API_URL}/getDataForCurrentMinutev2/${marcheId}/${leagueid}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
const getDataForCurrentAndLastMinute = async (marcheId) => {
  try {
    const response = await axios.get(`${API_URL}/getDataForCurrentAndLastMinute/${marcheId}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
const postAchat = async (userId, marche_id, price) => {
  try {
    const response = await axios.post(`${API_URL}/actions`, {
      user_id: userId,
      market_id: marche_id,
      price: price,
      // date: date,
    });
    return response.data;

  } catch (error) {
    if (error.response) {
      throw new Error(`Erreur du serveur : ${error.response.data.message}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
const funcAchat = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/handleAchat`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Assurez-vous que message existe avant d'y accéder
      const errorMessage = error.response.data.message || 'Erreur du serveur inconnue';
      throw new Error(`Erreur du serveur : ${errorMessage}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }


};
const funcVente = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/handleVente`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Assurez-vous que message existe avant d'y accéder
      const errorMessage = error.response.data.message || 'Erreur du serveur inconnue';
      throw new Error(`Erreur du serveur : ${errorMessage}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }


};

const funcGetTransactionsGroupedByMarket = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/portefeuille/transactions/${userId}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Assurez-vous que message existe avant d'y accéder
      const errorMessage = error.response.data.message || 'Erreur du serveur inconnue';
      throw new Error(`Erreur du serveur : ${errorMessage}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
const getTransactionsParMarche = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/portefeuille/getTransactionsParMarche/${userId}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Assurez-vous que message existe avant d'y accéder
      const errorMessage = error.response.data.message || 'Erreur du serveur inconnue';
      throw new Error(`Erreur du serveur : ${errorMessage}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};
// const getTransactionsParMarche = async (userId) => {
//   try {
//     const response = await axios.get(`${API_URL}/portefeuille/getVentesParMarche/${userId}`);
//     return response.data;
//   } catch (error) {
//     if (error.response) {
//       // Assurez-vous que message existe avant d'y accéder
//       const errorMessage = error.response.data.message || 'Erreur du serveur inconnue';
//       throw new Error(`Erreur du serveur : ${errorMessage}`);
//     } else if (error.request) {
//       throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
//     } else {
//       throw new Error(`Erreur inattendue : ${error.message}`);
//     }
//   }
// };
const getAchatsEtVentes = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/portefeuille/getAchatsEtVentes/${userId}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Assurez-vous que message existe avant d'y accéder
      const errorMessage = error.response.data.message || 'Erreur du serveur inconnue';
      throw new Error(`Erreur du serveur : ${errorMessage}`);
    } else if (error.request) {
      throw new Error('Pas de réponse du serveur. Veuillez vérifier votre connexion.');
    } else {
      throw new Error(`Erreur inattendue : ${error.message}`);
    }
  }
};


export { register, loginUser, forgetPassword, resetPassword, logoutUser, getDataMarchetodya, getMarches, getDataForCurrentAndLastMinute, getDataForCurrentMinutes, postAchat, funcAchat, funcVente, funcGetTransactionsGroupedByMarket, getTransactionsParMarche, getAchatsEtVentes, getArticles, getArticleById, addLike, addRate, addDislike, addComment, getVideos, getDefis, getImagesByMarketType, getMarchesByType };
