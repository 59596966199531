// // src/StackedBarChart.js
// import React from 'react';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const StackedBarChart = ({ userId }) => {
//         const data = {
//         labels: ['Semaine 1', 'Semaine 2', 'Semaine 3', 'Semaine 4'],
//         datasets: [
//             {
//                 label: 'Tom T.',
//                 backgroundColor: '#545454',
//                 data: [20000, 18000, 17000, 15000],
//             },
//             {
//                 label: 'Leo N.',
//                 backgroundColor: '#737373',
//                 data: [20000, 18000, 17000, 15000],
//             },
//             {
//                 label: 'Louis R..',
//                 backgroundColor: '#a6a6a6',
//                 data: [20000, 18000, 17000, 15000],
//             },
//             {
//                 label: 'Adriano I.',
//                 backgroundColor: '#FFF',
//                 data: [20000, 18000, 17000, 15000],
//             },
//         ],
//     };

//     const options = {
//         layout: {
//             padding: {
//                 top: 1, // Ajoute de l'espace entre le haut du conteneur et le graphique
//                 bottom: 20 // Ajoute de l'espace entre le bas du conteneur et le graphique
//             }
//         },
//         plugins: {
//             title: {
//                 display: true,
//             },

//             legend: {
//                 display: true,
//                 position: 'top',
//                 labels: {
//                     pointStyle: 'circle', // Utiliser le style de point "circle"
//                     usePointStyle: true, // Utiliser des styles de points pour les légendes
//                     pointRadius: 2, // Modifier le rayon des cercles de légende
//                     pointHoverRadius: 2, // Modifier le rayon des cercles de légende au survol
//                     pointBorderWidth: 2, // Modifier la largeur de bordure des cercles de légende
//                     boxWidth: 3, // Modifier la largeur des carrés de légende
//                     boxHeight: 4,
//                     padding: 10, // Ajouter du padding autour des labels de légende


//                 },
//             },
//         },
//         responsive: true,
//         maintainAspectRatio: false,
//         scales: {
//             x: {
//                 stacked: true,
//                 max: 80000,

//             },
//             y: {
//                 stacked: true,

//                 suggestedMax: 80000,
//             },
//         },
//     };

//     return (
//         <div style={{ width: '100%', height: '60vh' }}>
//             <Bar data={data} options={options} />
//         </div>
//     );
// };
// export default StackedBarChart;



// import React, { useState, useEffect } from 'react';
// import { Bar } from 'react-chartjs-2';
// import { getclassementbyteam } from '../../../Api/authApi.js';
// import { useSelector } from 'react-redux';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const StackedBarChart = ({ userId }) => {
//     const [chartData, setChartData] = useState(null);
//     const user = useSelector((state) => state.auth.user);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const result = await getclassementbyteam(user.id);
//                 // console.log(result);
                
//                 const labels = Object.keys(result.weekly_totals);
//                 const datasets = [];

//                 result.team_members.forEach((member, memberIndex) => {
//                     const memberData = {
//                         label: member.name,
//                         backgroundColor: getRandomColor(memberIndex), 
//                         data: []
//                     };

//                     labels.forEach((week) => {
//                         const weekData = result.weekly_totals[week].members.find(m => m.member_id === member.id);
//                         memberData.data.push(weekData ? weekData.total : 0);
//                     });

//                     datasets.push(memberData);
//                 });

//                 setChartData({
//                     labels: labels,
//                     datasets: datasets,
//                 });
//             } catch (error) {
//                 console.error('Erreur lors de la récupération des données du classement:', error);
//             }
//         };

//         fetchData();
//     }, [userId, user.id]);

//     const getRandomColor = (index) => {
//         const colors = [
//             '#545454', '#737373', '#a6a6a6', '#FFF', 
//             '#FF5733', '#33FF57', '#3357FF', '#FF33A6'
//         ]; 
//         return colors[index % colors.length];
//     };

//     const options = {
//         layout: {
//             padding: {
//                 top: 1,
//                 bottom: 6,
//             },
//         },
//         plugins: {
//             title: {
//                 display: true,
//             },
//             legend: {
//                 display: true,
//                 position: 'top',
//                 labels: {
//                     pointStyle: 'circle',
//                     usePointStyle: true,
//                     padding: 8,
//                 },
//             },
//         },
//         responsive: true,
//         maintainAspectRatio: false,
//         scales: {
//             x: {
//                 stacked: true,
//             },
//             y: {
//                 stacked: true,
//                 suggestedMax: 1000,
//             },
//         },
//     };

//     return (
//         <div style={{ width: '100%', height: '60vh' }}>
//             {chartData ? (
//                 <Bar data={chartData} options={options} />
//             ) : (
//                 <p>Chargement des données...</p>
//             )}
//         </div>
//     );
// };

// export default StackedBarChart;
// import React, { useEffect, useState } from 'react';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// import axios from 'axios';
// import { useSelector } from 'react-redux'; // Utiliser Redux pour récupérer l'utilisateur connecté
// import {  checktransactionsPourDefiActif } from '../../../Api/authApi.js';


// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const StackedBarChart = ({userId}) => {
//     const user = useSelector((state) => state.auth.user); // Récupérer l'utilisateur connecté depuis Redux
//     const [datasets, setDatasets] = useState([]); // Pour stocker les montants gagnés par membre pour chaque période
//     const [labels, setLabels] = useState([]); // Pour stocker les périodes (date_debut - date_fin)

//     useEffect(() => {
//             // Appel API pour récupérer les montants par membre de l'équipe et défi
//             const fetchEquipeData = async () => {
//                 try {
//                     const response = await checktransactionsPourDefiActif(userId);
//                     const montantsParEquipe = response.montants_par_equipe;
//                     const transactionsDefis = response.transactions_defis;

//                     // Variables pour stocker les périodes et membres
//                     const tempLabels = [];
//                     const tempDatasets = {}; // Les datasets pour chaque membre par période

//                     // Parcourir chaque période (transactions_defis)
//                     transactionsDefis.forEach(transaction => {
//                         const periode = `${transaction.periode.date_debut} - ${transaction.periode.date_fin}`;
//                         tempLabels.push(periode); // Ajouter la période comme label

//                         // Parcourir les membres de l'équipe et leurs montants pour cette période
//                         montantsParEquipe.forEach(member => {
//                             const memberId = member.user_id;
//                             const username = member.username;

//                             // Si le dataset pour ce membre n'existe pas encore, le créer
//                             if (!tempDatasets[memberId]) {
//                                 tempDatasets[memberId] = {
//                                     label: username, // Nom du membre
//                                     data: [], // Montants pour chaque période
//                                     backgroundColor: getRandomColor(), // Couleur aléatoire pour chaque membre
//                                 };
//                             }

//                             // Calculer le montant total gagné par ce membre pour cette période
//                             let montantTotalParMembre = 0;
//                             transaction.defis.forEach(defi => {
//                                 if (defi.defi_atteint) {
//                                     montantTotalParMembre += parseFloat(defi.montant_gagner); // Ajouter les montants des défis atteints
//                                 }
//                             });

//                             // Ajouter le montant total de ce membre pour cette période
//                             tempDatasets[memberId].data.push(montantTotalParMembre);
//                         });
//                     });

//                     // Mettre à jour les labels (périodes) et datasets (montants par membre)
//                     setLabels(tempLabels);
//                     setDatasets(Object.values(tempDatasets)); // Convertir l'objet en tableau pour Chart.js
//                 } catch (error) {
//                     console.error('Erreur lors de la récupération des données des défis:', error);
//                 }
//             };

//             fetchEquipeData();
        
//     }, [user]); // Lancer useEffect lorsqu'il y a un changement dans l'utilisateur

//     // Fonction pour générer des couleurs aléatoires
//     const getRandomColor = () => {
//         const letters = '0123456789ABCDEF';
//         let color = '#';
//         for (let i = 0; i < 6; i++) {
//             color += letters[Math.floor(Math.random() * 16)];
//         }
//         return color;
//     };

//     const data = {
//         labels: labels, // Périodes (date_debut - date_fin)
//         datasets: datasets, // Montants gagnés par membre de l'équipe pour chaque période
//     };

//     const options = {
//         layout: {
//             padding: {
//                 top: 1,
//                 bottom: 20,
//             }
//         },
//         plugins: {
//             title: {
//                 display: true,
//                 text: 'Montants gagnés par période de défi',
//             },
//             legend: {
//                 display: true,
//                 position: 'top',
//             },
//         },
//         responsive: true,
//         maintainAspectRatio: false,
//         scales: {
//             x: {
//                 stacked: true, // Les barres sont empilées pour chaque période
//             },
//             y: {
//                 stacked: true, // Les montants sont empilés pour chaque membre
//                 ticks: {
//                     beginAtZero: true, // Commencer l'axe Y à 0
//                 },
//             },
//         },
//     };

//     return (
//         <div style={{ width: '100%', height: '60vh' }}>
//             <Bar data={data} options={options} />
//         </div>
//     );
// };

// export default StackedBarChart;
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import { useSelector } from 'react-redux'; // Utiliser Redux pour récupérer l'utilisateur connecté
import {  checktransactionsPourDefiActif } from '../../../Api/authApi.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const StackedBarChart = ({userId}) => {
    const user = useSelector((state) => state.auth.user); // Récupérer l'utilisateur connecté depuis Redux
    const [datasets, setDatasets] = useState([]); // Pour stocker les montants gagnés par membre pour la période
    const [label, setLabel] = useState(''); // Pour stocker la période (date_debut - date_fin)
    const predefinedColors = ['#545454', '#737373', '#a6a6a6', '#FFF']; // 4 couleurs spécifiques

    useEffect(() => {
            // Appel API pour récupérer les montants par membre de l'équipe pour la période
            const fetchEquipeData = async () => {
                try {
                    const response = await checktransactionsPourDefiActif(userId);
                    const montantsParEquipe = response.montants_par_equipe;
                    const transactionsDefis = response.transactions_defis;

                    // Récupérer la période globale des défis
                    const periode = `${transactionsDefis[0].periode.date_debut} - ${transactionsDefis[0].periode.date_fin}`;
                    setLabel(periode); // La période couvre tous les défis

                    // Variables pour stocker les montants par membre
                    const tempDatasets = [];

                    // Parcourir les membres de l'équipe et leurs montants
                    montantsParEquipe.forEach((member, index) => {
                        tempDatasets.push({
                            label: member.username, // Nom du membre
                            data: [member.montant_total], // Montant total pour cette période
                            backgroundColor: predefinedColors[index % predefinedColors.length], // Appliquer une couleur prédéfinie
                            // barThickness: 30 + member.montant_total / 100, // Ajuster la largeur en fonction du montant

                        });
                    });

                    // Mettre à jour les datasets (montants par membre)
                    setDatasets(tempDatasets);
                } catch (error) {
                    console.error('Erreur lors de la récupération des données des défis:', error);
                }
            };

            fetchEquipeData();
        
    }, [userId]); // Lancer useEffect lorsqu'il y a un changement dans l'utilisateur

    // Fonction pour générer des couleurs aléatoires
  

    const data = {
        labels: [label], // Une seule période (date_debut - date_fin)
        datasets: datasets, // Montants gagnés par membre de l'équipe pour cette période
    };

    const options = {
        layout: {
            padding: {
                top: 1,
                bottom: 20,
            }
        },
        plugins: {
            title: {
                display: true,
                text: '',
            },
            legend: {
                display: true,
                position: 'top',
                labels: {
                    pointStyle: 'circle',
                    usePointStyle: true,
                    padding: 8,
                },
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true, // Les barres sont empilées pour chaque membre
                barPercentage: 0.5, // Réduire la largeur de chaque barre à 50% de l'espace disponible
                categoryPercentage: 0.9, // Ajuster l'espacement entre les barres (catégories)
                grid: {
                    display: false
                  }
            },
            y: {
                stacked: true, // Les montants sont empilés pour chaque membre

                ticks: {
                    beginAtZero: true, // Commencer l'axe Y à 0
                },
                grid: {
                    display: false
                  }
            },
        },
        categoryPercentage: 0.7 

    };

    return (
        <div style={{ width: '100%', height: '60vh' }}>
            <Bar data={data} options={options} />
        </div>
    );
};

export default StackedBarChart;
