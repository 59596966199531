

// export default Accueil;
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";

import clockset from '../../images/clockset.png'
import many from '../../images/many.png'
import params from '../../images/params.png'
import { useDispatch, useSelector } from 'react-redux';
import replay from '../../images/replay.png'
import Drawer from "../../components/Drawer";
import { Link, useNavigate } from "react-router-dom";
import Navbarfooter from "../../components/Navbarfooter";
import datablog from "../../components/datablog";
import start from '../../images/start.png'
import more from '../../images/more.png'
import good from '../../images/good.png'
import { funcGetTransactionsGroupedByMarket, getArticles, getVideos, getDefis, getArticleById, addLike, addRate, addDislike, addComment, getPortefeuilleData, getAllTransactions, checktransactionsPourDefiActif } from '../../Api/authApi.js';
import { useParams } from 'react-router-dom';
import { FaRegThumbsUp, FaRegStar, FaThumbsUp, FaStar } from 'react-icons/fa'; // Importation des icônes
import { CgMoreO } from "react-icons/cg";

import { toast, ToastContainer } from 'react-toastify';


const Accueil = () => {


    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [portefeuille, setPortefeuille] = useState({});
    const [transactions, setTransactions] = useState([]);
    const [portefeuilledata, setportefeuilledata] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [defis, setDefis] = useState([]);
    const [articles, setArticles] = useState([]);
    const icons = [good, start, more];
    const user = useSelector((state) => state.auth.user);
    const [likeClicked, setLikeClicked] = useState(false);
    const [dislikeClicked, setDislikeClicked] = useState(false);
    const [starClicked, setStarClicked] = useState(false);
    const [newComment, setNewComment] = useState(''); // Nouvel état pour le commentaire
    const userId = useSelector((state) => state.auth.user.id);
    const [comments, setComments] = useState([]); // Nouvel état pour stocker les commentaires
    const [article, setArticle] = useState({});
    const [defischeck, setdefischeck] = useState([]);
    const [remainingdays, setremainingdays] = useState([]);
    // Utiliser `user.id` comme dépendance pour que l'effet se mette à jour si l'utilisateur change
    // console.log('defischeck',defischeck)

    const toggleDrawer = () => {
        setIsDrawerOpen(prevState => !prevState);
    };
    const handleLikeClick = async (id) => {
        try {
            const response = await addLike(id, userId);
            setLikeClicked(true);
            setDislikeClicked(false); // Désélectionner dislike
            // console.log(response.data)

        } catch (err) {
            setError(err.message);
        }
    };

    const handleStarClick = async (id) => {
        try {
            const response = await addRate(id, userId);
            setStarClicked(true);
            // console.log(response.data)
        } catch (err) {
            setError(err.message);
        }
    };
    const handleCommentSubmit = async (id) => {
        try {
            const response = await addComment(id, userId, newComment);
            // Mettez à jour l'article avec le nouveau commentaire
            // Ajouter le nouveau commentaire à la liste des commentaires
            setComments([...comments, response.data.newComment]);
            // Réinitialisez le champ de commentaire
            setNewComment('');

            setArticle(response.data);
            // console.log(response.data)
        } catch (err) {
            setError(err.message);
        }
    };
    useEffect(() => {
        const fetchPortefeuilleData = async () => {
            if (user) {
                try {
                    const data = await funcGetTransactionsGroupedByMarket(user.id);
                    setPortefeuille(data.portefeuille);
                    // setTransactions(data.transactions_grouped_by_market);
                    // console.log('transactions_grouped_by_market', data.transactions_grouped_by_market)
                } catch (error) {
                    setError(error.message);
                    // setLoading(false);
                }
            }
        };

        fetchPortefeuilleData();
    }, [user]);
    useEffect(() => {
        const checkDefis = async () => {
            try {
                const response = await checktransactionsPourDefiActif(userId);
                // console.log('defischeck',response.montants_par_userconnecter)

                setremainingdays(response.jours_restants)
                setdefischeck(response.transactions_defis[0].defis); // Mettre à jour les défis


            } catch (error) {
                // console.error('Erreur lors de la vérification des défis', error);
            }

        };
        // Appel initial de la fonction
        checkDefis();

    }, []);
    useEffect(() => {
        const fetchPortefeuilleData = async () => {
            if (user) {
                try {
                    const data = await getAllTransactions(user.id);
                    setTransactions(data);
                    // console.log('trascation',data)
                } catch (error) {
                    setError(error.message);
                }
            }
        };

        fetchPortefeuilleData();
    }, [user]);
    useEffect(() => {
        const fetchDefis = async () => {
            try {
                const response = await getDefis(userId);
                setDefis(response.defis); // Assurez-vous que votre API retourne les défis dans une clé `defis`
                // console.log('defu',response)
            } catch (err) {
                setError(err.message);
            }
        };

        fetchDefis();
    }, []);
    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const articlesData = await getArticles();
                setArticles(articlesData);
                // console.log('articlesData', articlesData)
            } catch (err) {
                setError(err.message);
            }
        };

        fetchArticles();
    }, []);
    useEffect(() => {
        const fetchPortefeuilleData = async () => {
            try {
                const data = await getPortefeuilleData(user.id);
                setportefeuilledata(data);
                // console.log('getPortefeuilleData', data)

            } catch (error) {
                setError(error.message);
            }

        };

        fetchPortefeuilleData();
    }, [user]);

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('fr-FR', options);
    };

    return (
        <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] lg:pb-auto pb-12 pt-5">
            <div className=" w-full  bg-[#252f3d] ">
                <div className="grid  lg:grid-cols-2  md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 grid-cols-1 sm:grid-cols-1 flex-col items-center justify-center gap-3  lg:gap-7 sm:px-10 md:px-16">
                    <div className="lg:w-[98%] w-[98%] px-2 lg:border md:border-2 sm:border-2  lg:border-gray-200 sm:border-gray-200 md:border-gray-200  md:rounded-md sm:rounded-md    border-gray-600 lg:rounded-lg shadow bg-balck text-white flex justify-between mx-auto border-b py-1 lg:p-[18px]">
                        <div className="w-[100%] flex flex-col gap-4">
                            <div className="w-[100%] flex ">
                                <div className="  w-1/2 space-y-2  py-1 ">
                                    <p className="font-sans font-bold  lg:text-[20px] px-1 text-[13.5px] " style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Mon portefeuille
                                    </p>
                                    <div className="flex items-center justify-between pb-1  px-1 gap-1">
                                        <p className="lg:text-[22px] font-extrabold text-[16px]">
                                            {portefeuille.montant_total} €
                                        </p>
                                        <div className="flex flex-col gap-1 justify-center">
                                            {/* <span className={`lg:text-[20px] flex flex-col font-bold text-[11px] ${portefeuilledata && portefeuilledata.latest_gain_loss < 0 ? 'text-red-500' : 'text-[#6ca82c]'}`}>
                                                {portefeuilledata ? `${portefeuilledata.latest_gain_loss !== null ? parseFloat(portefeuilledata.latest_gain_loss).toFixed(2) : '0.00'} €` : 'Loading...'}
                                            </span> */}
                                            <span className={`lg:text-[20px] flex flex-col font-bold text-[11px] ${portefeuilledata && portefeuilledata.latest_gain_loss < 0 ? 'text-red-500' : 'text-[#6ca82c]'}`}>
                                                {portefeuilledata ?
                                                    (portefeuilledata.latest_gain_loss !== null && !isNaN(portefeuilledata.latest_gain_loss) ?
                                                        parseFloat(portefeuilledata.latest_gain_loss).toFixed(2) : '0.00')
                                                    : 'Loading...'} €
                                            </span>

                                        </div>

                                    </div>
                                    <div>
                                        <p className="lg:text-[22px]   font-bold text-[16px]">
                                            {portefeuilledata ? formatDate(portefeuilledata.updated_at) : 'Loading...'}
                                        </p>


                                    </div>

                                </div>
                                <div className=" w-1/2  border-l border-gray-500 py-1">
                                    <p className=" font-sans font-bold  lg:text-[20px] lg:px-6 px-3 pb-3 text-[12px] flex justify-between " style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Vos positions<span><svg class="w-5 h-5 text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-7 7V5" />
                                    </svg>
                                    </span>
                                    </p>

                                    <div className="">

                                        {transactions.slice(0, 2).map((transaction) => (

                                            <div key={transaction.market_id} className="flex justify-around py-1">
                                                <img src={`https://tradingseries.fr/admin/storage/app/public/${transaction.market_icon}`} className="items-center flex"
                                                    alt="Position" height={40} width={40} />
                                                <p className={` font-normal lg:text-[20px] text-[11px] flex items-center ${transaction.gain_loss < 0 ? 'text-red-500' : 'text-[#6ca82c]'}`}>
                                                    {transaction.gain_loss > 0 && ("+")}{transaction.gain_loss}€
                                                    <span className="flex justify-center items-center pl-1">
                                                        <svg className={`w-4 h-4 ${transaction.gain_loss < 0 ? 'text-red-500' : 'text-[#6ca82c]'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={transaction.gain_loss < 0 ? "m19 9-7 7-7-7" : "m5 15 7-7 7 7"} />
                                                        </svg>
                                                    </span>
                                                </p>
                                                <span className="flex justify-center items-center">
                                                    <svg className="w-4 h-4 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 17.94 6M18 18 6.06 6" />
                                                    </svg>
                                                </span>
                                            </div>
                                        ))}
                                        <Link to={'/PorteFeuille'} className=" flex text-[11px] justify-end">  (et plus...)
                                        </Link>



                                    </div>
                                </div>
                            </div>
                            <div className="pb-3">
                                <div className="w-[100%] flex  lg:flex-row flex-col items-center justify-center  ">
                                    <Link to='/portefeuille_menu' className=" bg-white text-black text-center w-[98%]  p-2 rounded-sm  font-sans font-bold text-[14px]">   <button >Investir</button></Link>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="lg:w-[98%] w-[98%] lg:p-4 p-2 lg:border-gray-200 lg:rounded-lg shadow bg-balck text-white flex justify-between mx-auto lg:border md:border-2 sm:border-2   sm:border-gray-200 md:border-gray-200  md:rounded-md sm:rounded-md    border-gray-500 py-2 pb-3 border-b ">
                        <div className="w-[100%] flex flex-col gap-2">
                            <p className=" font-sans font-bold  lg:text-md text-[14px] pb-4" style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Participe aux défis pour prendre une longueur d'avance sur le classement !
                            </p>


                            {defischeck.map((item) => (
                                <div className="flex justify-between gap-3 py-1" key={item.id}>
                                    <div className="flex gap-2">
                                        <img
                                            src={`https://tradingseries.fr/admin/storage/app/public/${item.icon}`}
                                            alt={item.titre_defis}
                                            className="object-scale-down lg:w-12 lg:h-12 h-16 w-16"
                                        />
                                        <span>
                                            <p className="text-[11px] lg:text-[15px] font-semibold">{item.titre_defis}</p>
                                            <span className="text-[#6ca82c] font-mono lg:text-[14px] text-[16px]">{item.montant_gagner}€</span>
                                        </span>
                                    </div>
                                    <span>
                                        <svg
                                            className={`w-5 h-5 ${item.defi_atteint ? 'text-green-500' : 'text-white'}`}  // Classe conditionnelle
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                            />
                                        </svg>
                                    </span>
                                </div>
                            ))}


                            <div className="flex gap-2">
                                <img src={replay} className="object-scale-down w-4 h-4" />

                                <p className="text-[13px] lg:text-[13px] text-gray-300" style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Il reste {remainingdays} jours avant l'actualisation des défis
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="lg:grid-cols-2 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 grid-cols-1 sm:grid-cols-1 flex-col items-center justify-center gap-2  ">
                    <div className="lg:mx-12 px-4 md:px-4 sm:p-10 md:p-16 py-2">
                        <p className="text-white lg:text-lg font-semibold py-3 text-[14px] " style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Découvre la finance et
                            l’au-delà ici !</p>
                        {articles.length === 0 ? (
                            <p>Aucun article disponible.</p>
                        ) : (
                            <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 sm:gap-5 md:gap-5 lg:gap-5 xl:gap-5 2xl:gap-5 gap-1 ">
                                {articles.map((item) => (
                                    <div key={item.id}

                                        className="overflow-hidden shadow-lg bg-[#252f3d] sm:border-2 md:border-2 lg:border-2 xl:border-2 2xl:border-2 sm:rounded-md md:rounded-md lg:rounded-md xl:rounded-md 2xl:rounded-md">
                                        <Link to={`/article/${item.id}`} className="relative">
                                            {/* <img className="w-[100%] lg:[w-100%]  h-60 " src={`https://tradingseries.fr/admin/storage/app/public/${item.image}`} alt={item.title} /> */}
                                            <img
                                                className="w-full h-auto lg:w-full lg:h-[1440px] object-cover"
                                                style={{ maxWidth: '2560px', maxHeight: '1440px' }}
                                                src={`https://tradingseries.fr/admin/storage/app/public/${item.image}`}
                                                alt={item.title}
                                            />

                                        </Link>

                                        <div className="px-2 py-2">
                                            <p className="text-[11px] lg:text-[15px] text-white  font-medium">{item.title}</p>
                                            <span className="flex justify-end gap-1">
                                                {/* {icons.map((icon, iconIndex) => (
                                                    <span
                                                        key={iconIndex}
                                                        className={`icon-class`}
                                                    >
                                                        <img key={iconIndex} className="w-auto h-auto" src={icon} alt="Icon" />
                                                    </span>
                                                ))} */}
                                                <button onClick={() => handleLikeClick(item.id)}>
                                                    {likeClicked ? <FaThumbsUp className="text-white text-xl" /> : <FaRegThumbsUp className="text-white text-xl" />}
                                                </button>



                                                <Link className=" items-center flex "
                                                    to={`/article/${item.id}`}>
                                                    <button >
                                                        {dislikeClicked ? <CgMoreO className="text-white text-xl" /> : <CgMoreO className="text-white text-xl" />}
                                                    </button>
                                                </Link>

                                                {/* <button onClick={handleStarClick(item.id)}> */}
                                                <button onClick={() => handleStarClick(item.id)}>
                                                    {starClicked ? <FaStar className="text-white text-xl" /> : <FaRegStar className="text-white text-xl" />}
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>


                </div>


            </div>

        </div>



    )
};
export default Accueil;
