


// import React, { useEffect, useState } from 'react';

// const StockChart = ({ marcheId,userId ,leagueid}) => {
//   const [dps1, setDps1] = useState([]); // Stocker les données récupérées
//   const [chart, setChart] = useState(null); // Stocker le graphique
//   const [loading, setLoading] = useState(true); // Loader pour indiquer le chargement
//   const [selectedPeriod, setSelectedPeriod] = useState('1min'); // Période sélectionnée

//   useEffect(() => {
//     // Initialiser le graphique lors du premier rendu
//     const stockChart = new window.CanvasJS.StockChart("chartContainer", {
//       theme: "light2",
//       exportEnabled: false,
//       navigator: { enabled: false },
//       rangeSelector: { inputFields: { enabled: false }, enabled: false },
//       backgroundColor: "",
//       charts: [{
//         axisX: { crosshair: { enabled: false, snapToDataPoint: true, }, labelFontColor: "#5a5a5a", lineColor: "#5a5a5a", tickColor: "#yellow",       labelFormatter: function (e) {
//           const date = new Date(e.value);  // e.value correspond à l'heure
//           let hours = date.getHours();  // Récupère les heures en format 24h
//           const minutes = date.getMinutes();  // Récupère les minutes
//           const formattedTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
//           return formattedTime;
//         }},
        
//         axisY: { prefix: "", labelFontColor: "#5a5a5a", gridThickness: 1, gridColor: "#252f3d", tickLength: 0, lineThickness: 0, lineColor: "transparent" ,labelFormatter: function(e) {
//           return "";
//       }},
//         data: [{
//           type: "candlestick",
//           yValueFormatString: "$#,###.##",
//           dataPoints: dps1,
//           risingColor: "#00ff00",
//           fallingColor: "#ff0000",
//         }]
//       }]
//     });

//     setChart(stockChart);
//     stockChart.render(); // Afficher le graphique

//     // Charger les données par défaut et toutes les 60 secondes
//     fetchData('All'); // Charger les données par défaut pour 30 minutes
//     const interval = setInterval(() => {
//       fetchData(selectedPeriod); // Re-fetch les données toutes les 60 secondes
//     }, 60000); // 60 secondes

//     return () => clearInterval(interval); // Nettoyer l'intervalle lors du démontage
//   }, []);
//   // const API_URL = 'https://tradingseries.fr/admin/public/api';
//   const API_URL = 'http://localhost:8000/api/';

//   // Fonction pour récupérer les données de l'API
//   const fetchData = async (period) => {
//     setLoading(true); // Afficher le loader
//     let url = "";
  


//     switch (period) {
//       case 'All':
//         url = `https://tradingseries.fr/admin/public/api/getDataFromLastPeriodbougies/${marcheId}/${leagueid}/All`;
//         break;
//       case '240':
//         url = `https://tradingseries.fr/admin/public/api/getDataFromLastPeriodbougies/${marcheId}/${leagueid}/240`;
//         break;
//       case '300':
//         url = `https://tradingseries.fr/admin/public/api/getDataFromLastPeriodbougies/${marcheId}/${leagueid}/300`;
//         break;
//       case '1Y':
//         url = `https://tradingseries.fr/admin/public/api/getDataFromLastPeriodbougies/${marcheId}/${leagueid}/All`;
//         break;
//       case '5Y':
//         url = `https://tradingseries.fr/admin/public/api/getDataFromLastPeriodbougies/${marcheId}/${leagueid}/All`;
//         break;
//       default:
//         url = `https://tradingseries.fr/admin/public/api/getDataFromLastPeriodbougies/${marcheId}/${leagueid}/All`;
//     }

//     try {
//       const response = await fetch(url);
//       const data = await response.json();

//       const dataPoints = data.values.map(item => {
//         let high = Number(item.high);
//         let low = Number(item.low);

//         // Si la différence entre high et low est trop faible, ajouter une marge artificielle
//         const difference = high - low;
//         const minDifference = 0.2; // Seuil minimum pour la différence

//         if (difference < minDifference) {
//           const margin = (minDifference - difference) / 2;
//           high += margin;
//           low -= margin;
//         }

//         return {
//           x: new Date(`${data.date} ${item.minute}`),
//           y: [Number(item.open), high, low, Number(item.close)],
//           color: item.color
//         };
//       });

//       setDps1(dataPoints); // Mettre à jour les data points
//       updateChart(dataPoints); // Mettre à jour rapidement le graphique
//     } catch (error) {
//       console.error("Erreur lors de la récupération des données :", error);
//     } finally {
//       setLoading(false); // Cacher le loader
//     }
//   };

//   // Fonction pour mettre à jour les données du graphique
//   const updateChart = (dataPoints) => {
//     if (chart) {
//       chart.options.charts[0].data[0].dataPoints = dataPoints; // Mettre à jour les points de données
//       chart.render(); // Réafficher le graphique avec les nouvelles données
//     }
//   };

//   // Gestion de la sélection de la période
//   const handlePeriodSelect = (period) => {
//     setSelectedPeriod(period); // Mettre à jour la période sélectionnée
//     fetchData(period); // Charger les nouvelles données pour la période sélectionnée
//   };

//   return (
//     <div>
//       {loading && <p>Chargement des données...</p>} {/* Afficher un loader pendant le chargement */}
//       <div id="chartContainer" style={{ height: 360, width: '100%' }}></div>
//       <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
//         <button
//           style={selectedPeriod === '1m' ? selectedButtonStyle : buttonStyle}
//           onClick={() => handlePeriodSelect('All')}
//         >
//           1M
//         </button>
//         <button
//           style={selectedPeriod === '10min' ? selectedButtonStyle : buttonStyle}
//           onClick={() => handlePeriodSelect('240')}
//         >
//           10M
//         </button>
//         <button
//           style={selectedPeriod === '30min' ? selectedButtonStyle : buttonStyle}
//           onClick={() => handlePeriodSelect('300')}
//         >
//           30M
//         </button>
//         <button
//           style={selectedPeriod === 'hour' ? selectedButtonStyle : buttonStyle}
//           onClick={() => handlePeriodSelect('All')}
//         >
//           1H
//         </button>
//         <button
//           style={selectedPeriod === 'day' ? selectedButtonStyle : buttonStyle}
//           onClick={() => handlePeriodSelect('All')}
//         >
//           1J
//         </button>
//       </div>
//     </div>
//   );
// };

// // Styles pour les boutons
// const buttonStyle = {
//   padding: '1px',
//   backgroundColor: '#252f3d',
//   color: 'gray',
//   borderRadius: '5px',
//   margin: '5px',
//   border: 'none',
// };

// const selectedButtonStyle = {
//   ...buttonStyle,
//   backgroundColor: '#252f3d', // Couleur différente pour le bouton sélectionné
//   color: 'white',

// };

// export default StockChart;
import React, { useEffect, useState } from 'react';

const StockChart = ({ marcheId, userId, leagueid }) => {
  const [dps1, setDps1] = useState([]); // Stocker les données récupérées
  const [chart, setChart] = useState(null); // Stocker le graphique
  const [loading, setLoading] = useState(true); // Loader pour indiquer le chargement
  const [selectedPeriod, setSelectedPeriod] = useState('60'); // Période sélectionnée

  useEffect(() => {
    // Initialiser le graphique lors du premier rendu
    const stockChart = new window.CanvasJS.StockChart("chartContainer", {
      theme: "light2",
      exportEnabled: false,
      navigator: { enabled: false },
      rangeSelector: { inputFields: { enabled: false }, enabled: false },
      backgroundColor: "",
      charts: [{
        axisX: {
          crosshair: { enabled: false, snapToDataPoint: true },
          labelFontColor: "#5a5a5a",
          lineColor: "#5a5a5a",
          tickColor: "#yellow",
          labelFormatter: function (e) {
            const date = new Date(e.value);  // e.value correspond à l'heure
            let hours = date.getHours();  // Récupère les heures en format 24h
            const minutes = date.getMinutes();  // Récupère les minutes
            const formattedTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
            return formattedTime;
          }
        },
        axisY: {
          prefix: "", labelFontColor: "#5a5a5a", gridThickness: 1, gridColor: "#252f3d", tickLength: 0, lineThickness: 0, lineColor: "transparent",
          labelFormatter: function (e) {
            return "";
          }
        },
        data: [{
          type: "candlestick",
          yValueFormatString: "$#,###.##",
          dataPoints: dps1,
          
          risingColor: "#00ff00",
          fallingColor: "#ff0000",
        }]
      }]
    });

    setChart(stockChart);
    stockChart.render(); // Afficher le graphique

    // Charger les données par défaut
    fetchData(selectedPeriod);

    const interval = setInterval(() => {
      fetchData(selectedPeriod); // Re-fetch les données toutes les 60 secondes
    }, 60000); // 60 secondes

    return () => clearInterval(interval); // Nettoyer l'intervalle lors du démontage
  }, [selectedPeriod]);

  // const API_URL = 'http://localhost:8000/api/';
  const API_URL = 'https://tradingseries.fr/admin/public/api/';

  // Fonction pour récupérer les données de l'API
  const fetchData = async (period) => {
    // console.log(`Fetching data for period: ${period}`); // Ajoutez cette ligne pour déboguer

    setLoading(true); // Afficher le loader
    let url = "";

    switch (period) {
      case '60':
        url = `${API_URL}getDataFromLastPeriodbougies/${marcheId}/${leagueid}/60`;
        break;
      case '180':
        url = `${API_URL}getDataFromLastPeriodbougies/${marcheId}/${leagueid}/180`;
        break;
      case '200':
        url = `${API_URL}getDataFromLastPeriodbougies/${marcheId}/${leagueid}/200`;
        break;
      case '240':
        url = `${API_URL}getDataFromLastPeriodbougies/${marcheId}/${leagueid}/240`;
        break;
   
      default:
        url = `${API_URL}getDataFromLastPeriodbougies/${marcheId}/${leagueid}/All`;
    }

    try {
      const response = await fetch(url);
      const data = await response.json();
      // console.log('Data received:', data); // Loguer les données pour voir ce qui est retourné

      const dataPoints = data.values.map(item => {
        let high = Number(item.high);
        let low = Number(item.low);

        // Si la différence entre high et low est trop faible, ajouter une marge artificielle
        const difference = high - low;
        const minDifference = 0.04; // Seuil minimum pour la différence

        if (difference < minDifference) {
          const margin = (minDifference - difference) / 2;
          high += margin;
          low -= margin;
        }

        return {
          x: new Date(`${data.date} ${item.minute}`),
          y: [Number(item.open), high, low, Number(item.close)],
          color: item.color
        };
      });

      setDps1(dataPoints); // Mettre à jour les data points
      updateChart(dataPoints); // Mettre à jour rapidement le graphique
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setLoading(false); // Cacher le loader
    }
  };

  // Fonction pour mettre à jour les données du graphique
  const updateChart = (dataPoints) => {
    if (chart) {
      chart.options.charts[0].data[0].dataPoints = dataPoints; // Mettre à jour les points de données
      chart.render(); // Réafficher le graphique avec les nouvelles données
    }
  };

  // Gestion de la sélection de la période
  const handlePeriodSelect = (period) => {
    setSelectedPeriod(period); // Mettre à jour la période sélectionnée
    fetchData(period); // Charger les nouvelles données pour la période sélectionnée
  };

  return (
    // Pour les temps des graphismes nous proposons afin de ne pas toucher à la base : 1m =1j, 10mn = 1w, 30 mn = 1M, 1h = 1Y,  1j = 5Y pour garder les 5 divisions, on pourra revoir les divisions (6.7.8...) en V2.




    <div>
      {loading && <p>Chargement des données...</p>} {/* Afficher un loader pendant le chargement */}
      <div id="chartContainer" style={{ height: 360, width: '100%' }}></div>
      <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
        <button
          style={selectedPeriod === '60' ? selectedButtonStyle : buttonStyle}
          onClick={() => handlePeriodSelect('60')}
        >
          1J
        </button>
        <button
          style={selectedPeriod === '180' ? selectedButtonStyle : buttonStyle}
          onClick={() => handlePeriodSelect('180')}
        >
          1W
        </button>
        <button
          style={selectedPeriod === '200' ? selectedButtonStyle : buttonStyle}
          onClick={() => handlePeriodSelect('200')}
        >
          1M
        </button>
        <button
          style={selectedPeriod === '240' ? selectedButtonStyle : buttonStyle}
          onClick={() => handlePeriodSelect('240')}
        >
          1Y
        </button>
        <button
          style={selectedPeriod === 'All' ? selectedButtonStyle : buttonStyle}
          onClick={() => handlePeriodSelect('All')}
        >
          5Y
        </button>
      </div>
    </div>
  );
};

// Styles pour les boutons
const buttonStyle = {
  padding: '1px',
  backgroundColor: '#252f3d',
  color: 'gray',
  borderRadius: '5px',
  margin: '5px',
  border: 'none',
};

const selectedButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#252f3d', // Couleur différente pour le bouton sélectionné
  color: 'white',
};

export default StockChart;
