
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import PieChartComponent from "../../components/chartgraphique/polarareachart";
import { getAllPendingTransactions, getTransactionDetails, closeTransaction } from '../../Api/authApi.js';

import { toast } from 'react-toastify';


const PorteFeuille = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [portefeuille, setPortefeuille] = useState({});
    const [transactions, setTransactions] = useState([]);
    const [error, setError] = useState(null);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const user = useSelector((state) => state.auth.user);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    useEffect(() => {
        const fetchPendingTransactions = async () => {
            try {
                const data = await getAllPendingTransactions(user.id);
                setTransactions(data.transactions_pending
                ); // Mettre à jour les transactions avec les achats et ventes en attente
                // console.log(data.transactions_pending)

                // console.log(data); // Log the data to verify the response
            } catch (error) {
                setError(error.message);
            }
        }

        fetchPendingTransactions();
        const intervalId = setInterval(() => {
            fetchPendingTransactions();
        }, 60000);

        return () => clearInterval(intervalId);
    }, [user]);


    const handleTransactionClick = async (uniqueId, currentValue) => {
        try {
            // Appeler l'API closeTransaction pour fermer la position en envoyant current_value
            const response = await closeTransaction(uniqueId, currentValue);
    
            // Si la transaction est fermée avec succès
            // console.log('Transaction fermée :', response);
            toast.success('Transaction fermée ');

            // Actualiser la liste des transactions en attente après la fermeture
            const data = await getAllPendingTransactions(user.id);
            setTransactions(data);
        } catch (error) {
            console.error('Erreur lors de la fermeture de la transaction :', error);
            setError('Erreur lors de la fermeture de la transaction.');
        }
    };
    


    return (
        <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] lg:py-2 pb-12">
            <div className="grid lg:grid-cols-2 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 grid-cols-1 sm:grid-cols-1 flex-col items-center justify-center gap-4 sm:px-10 md:px-16">
                <div className="lg:w-[98%] w-[98%] p-2 lg:border border-gray-200 lg:rounded-lg shadow bg-[#252f3d] text-white flex justify-center mx-auto py-10 px-3 flex-col md:border md:border-gray-200 md:rounded-lg sm:border sm:border-gray-200 sm:rounded-lg">
                    <h1 className="flex justify-start text-left px-2 font-bold text-[15px] lg:text-[20px]">Mon portefeuille</h1>
                    <PieChartComponent />
                </div>
                <div className="lg:w-[98%] w-[98%] lg:border md:border md:border-gray-200 md:rounded-lg sm:border sm:border-gray-200 sm:rounded-lg lg:border-gray-200 lg:rounded-lg shadow bg-[#252f3d] text-white flex justify-center mx-auto px-3 flex-col py-4 lg:py-5 border-t border-gray-500 p-2">
                    <div className="flex lg:justify-between justify-around gap-3 py-5">
                        <p className="font-bold text-[15px] lg:text-[20px]">Mes positions</p>
                        <Link to="/portefeuille_historique" className="border rounded-sm border-gray-500 font-semibold flex justify-center px-7 lg:rounded-md text-[11px] lg:text-[15px] text-center items-center">Historique</Link>
                    </div>
                    <div>
                        <div className="mb-1 flex pl-2 gap-6 justify-between items-center w-[100%] overflow-auto">
                            <span className="p-2 font-medium text-gray-900"></span>
                            <span className="font-semibold text-[12px] lg:text-sm">Nombre</span>
                            <span className="font-semibold text-[12px] lg:text-sm">Valeur</span>
                            <span className="font-semibold text-[12px] lg:text-sm">G/P</span>
                            <span className="font-semibold text-[12px] lg:text-sm">Fermer</span>
                        </div>
                        {/* Afficher les transactions d'achats */}
                        {transactions && transactions.length > 0 ? (
                            transactions.map((item) => (
                                <div key={item.unique_id} className="border border-gray-500 mb-4 flex gap-4 justify-between items-center w-[100%] overflow-auto">
                                    <span className="p-2 font-medium text-gray-900">
                                        <img src={`https://tradingseries.fr/admin/storage/app/public/${item.market_icon}`} className="w-6 h-6" alt="Logo" />
                                    </span>
                                    <span className="font-normal text-sm">{item.quantity}</span>
                                    <span className="font-normal text-sm">{item.price}</span>
                                    <span className="font-normal text-sm" style={{ color: item.gain_perte_color }}>{Number(item.difference).toFixed(2)}</span>
                                    {/* <span className="font-normal text-sm" style={{ color: item.gain_loss >= 0 ? 'green' : 'red' }}>
                                        {item.gain_loss || '--'}
                                    </span> */}
                                    <span className="px-3 py-1" onClick={() => handleTransactionClick(item.unique_id, item.current_value)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-x-lg" viewBox="0 0 16 16">
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                        </svg>
                                    </span>

                                </div>
                            ))
                        ) : (
                            <p className="text-blue-300 text-[10px] py-4 " > </p>
                        )}

                    
                        <Link to={'/portefeuille_menu'} className=" flex text-[11px] justify-end cursor-pointer">
                        <button className="mb-1 py-2.5 flex justify-center items-center w-[100%] bg-white rounded-sm text-black font-bold text-sm">
                            Ouvrir un trade
                        </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PorteFeuille;