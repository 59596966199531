
// export default ProgressiveLineChart;
// v2
import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
// const API_URL = 'http://127.0.0.1:8000/api';

const API_URL = 'https://tradingseries.fr/admin/public/api';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

// timeOptions defined here
const timeOptions = [
  // { key: 'weekend', label:'',function: 'getDataForCurrentOrFriday' },
  { key: 'All', label:'1min',function: 'getDatabayPeriod' },
  { key: '20', label:'5min',function: 'getDatabayPeriod' },
  { key: '30', label:'15min',function: 'getDatabayPeriod' },
  { key: '40', label:'30min',function: 'getDatabayPeriod' },
  { key: '60', label:'1h',function: 'getDatabayPeriod' },
  { key: '240', label:'4h',function: 'getDatabayPeriod' },
  { key: 'All', label:'1D',function: 'getDatabayPeriod' },
  { key: 'All', label:'1W',function: 'getDatabayPeriod' }
];

const ProgressiveLineChart = ({ selectedTime, marcheId, userId,leagueid }) => {
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [fullLabels, setFullLabels] = useState([]);
  const chartRef = useRef();

  const fetchData = async (timeKey) => {
    let timeOption = timeOptions.find(option => option.key === timeKey);
    
    if (timeOption) {
      try {
        const response = await axios.get(`${API_URL}/${timeOption.function}/${marcheId}/${leagueid}/${timeOption.key}`);
        const data = response.data;
        // console.log('ProgressiveLineChart',data)

        if (data && data.values) {
          const date = data.date;
          const values = data.values.map(item => item.value);
          const fullLabels = data.values.map(item => `${date} ${item.heures}`);

          setData(values);
          setLabels(data.values.map(item => item.heures));
          setFullLabels(fullLabels);
        } else {
          // Si aucune donnée n'est récupérée, réinitialiser les données
          setData([]);
          setLabels([]);
          setFullLabels([]);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    }
  };

  useEffect(() => {
    // Fetch initial data
    fetchData(selectedTime);

    // Set up interval to fetch data every minute
    const intervalId = setInterval(() => {
      fetchData(selectedTime);
    }, 60000); // 60000ms = 1 minute

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [selectedTime, marcheId]);

  const handlePointClick = (event, elements) => {
    if (elements.length > 0) {
      const elementIndex = elements[0].index;
      const value = data[elementIndex];
      const fullLabel = fullLabels[elementIndex];
      // console.log(`Point cliqué - Date et Heure: ${fullLabel}, Valeur: ${value}`);
    }
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        borderColor: 'gray',
        borderWidth: 0.5,
        radius: 0.5,
        data: data,
      }
    ]
  };

  const options = {
    animation: false,
    interaction: {
      intersect: false,
      mode: 'nearest'
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const index = context.dataIndex;
            const fullLabel = fullLabels[index];
            const value = data[index];
            return `${fullLabel}\n ${value}`;
          }
        }
      }
    },
    onClick: handlePointClick,
    scales: {
      x: {
        type: 'category',
        grid: {
          display: false
        },
        border: {
          display: false
        },
        title: {
          display: true,
        },
        ticks: {
          display: false,
          font: {
            // display: false,
            size: 8,
          }
        }

      },
      y: {
        title: {
          display: false,
        },
        border: {
          display: false
        },
        grid: {
          display: false
        },
        ticks: {
          display: false,
        },
      }
    }
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ position: 'relative', width: '100%', height: '100%', }}>
        <Line ref={chartRef} data={chartData} options={options} />
      </div>
    </div>
  );
};

export default ProgressiveLineChart;
